<template>
  <div class="home animated fadeInUp">
    <div class="homeheader flexmode hc hb p20">
      <div class="headerleft">
        <router-link to="/vup">vup</router-link>
        <!-- <router-link class="ml10" to="/acmusic">繁星唱见</router-link> -->
      </div>
      <div class="headerright">
        <div class="button_click" @click="loginhandle" v-if="!islogin">
          登录/注册
        </div>
        <div v-else>
          <Avatar />
        </div>
      </div>
    </div>
    <div class="homewrapper">
      <div class="captaion">
        <span class="webtitle">第九行诗</span>
      </div>
      <div class="search">
        <span class="searcharea flexmode hc">
          <span><search-outlined /></span>
          <a-input
            v-model:value="searchvalue"
            :bordered="false"
            placeholder="找找"
            @pressEnter="gosearch"
          />
        </span>
      </div>
      <div class="picswiper">
        <HfadeinVue />
      </div>
    </div>
    <Loginpage />
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { SearchOutlined } from "@ant-design/icons-vue";
import HfadeinVue from "./home/components/hfadein.vue";
// import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Loginpage from "../components/loginpage/index.vue";
import Avatar from "../layout/components/avatar.vue";

export default {
  components: {
    SearchOutlined,
    HfadeinVue,
    Loginpage,
    Avatar,
  },
  setup() {
    // const router = useRouter();
    const searchvalue = ref("");
    const store = useStore();
    const islogin = computed(() => store.state.Login.islogin);
    function gosearch() {
      if (searchvalue.value !== "") {
        // router.push({ path: "/searchd", query: { name: searchvalue.value } });
      }
    }
    function loginhandle() {
      store.commit("common/setVisible", true);
    }
    return {
      searchvalue,
      islogin,
      gosearch,
      loginhandle,
    };
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  // background-image: radial-gradient(
  //   circle,
  //   #ffffff,
  //   #f5efff,
  //   #f2ddfb,
  //   #f3caf3,
  //   #f7b6e6,
  //   #faadde,
  //   #fca3d6,
  //   #ff99cc,
  //   #ff99cc,
  //   #ff99cc,
  //   #ff99cc,
  //   #ff99cc
  // );
  // background: url("~@/assets/other/homebg.gif");
  background: url("https://assets.floydzero.com/vupimg/homebg.gif");
  // background: linear-gradient(to left top, #ffffff, #ffe8f7);
  .homeheader {
    height: 50px;
    // background: #ff99ff;
    .headerleft {
      a {
        color: #e4f0f5;
        font-size: 20px;
        :hover {
          box-shadow: -4px 2px 4px 0px rgba(0, 0, 0, 0.1);
          color: #f9f871;
        }
      }
    }
  }
  .homewrapper {
    .captaion {
      height: 60px;
      text-align: center;
      line-height: 60px;
      margin-bottom: 20px;
      color: white;
      .webtitle {
        font-size: 30px;
      }
    }
    .search {
      padding: 0 24px;
      background: #fff;
      border: solid 1px #ccc;
      width: 560px;
      border-radius: 32px;
      position: relative;
      margin: auto;
      z-index: 1;
      .searcharea {
        height: 56px;
      }
    }
  }
  .search :hover {
    box-shadow: hsla(209, 100%, 50%, 0.15) 0 0 15px;
  }
}
</style>
