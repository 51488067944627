<template>
  <div class="Verticalswiper">
    <div class="swiper">
      <swiper
        :direction="'vertical'"
        :loop="true"
        :slidesPerView="2"
        :spaceBetween="30"
        :slidesPerGroup="1"
        :autoplay="autoplayOptions"
        :modules="modules"
        class="mySwiper"
      >
        <swiper-slide v-for="item in vupdata" :key="item.id">
          <div class="haveicon point" @click="gosearchdetail(item)">
            <a-avatar :size="64" :src="item.vupFace">
              <!-- <template #icon><UserOutlined /></template> -->
            </a-avatar>
            <div class="userinfo point">
              <div class="infoname mt5">{{ item.vupName }}</div>
              <div class="infotitle mt10">{{ item.vupSign }}</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import { Pagination, Navigation, Autoplay } from "swiper";
import { useRouter } from "vue-router";
export default {
  components: {
    // Fbook,
    Swiper,
    SwiperSlide,
  },
  props: ["vupdata"],
  setup() {
    const router = useRouter();
    const autoplayOptions = ref({
      delay: 3000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      loopAdditionalSlides: 0,
      // reverseDirection: true,
    });

    function gosearchdetail(item) {
      localStorage.setItem("vupdetail", JSON.stringify(item));
      router.push({ path: "/vup/vupdetail", query: { detailid: item.vupid } });
    }
    return {
      gosearchdetail,
      autoplayOptions,
      modules: [Pagination, Navigation, Autoplay],
    };
  },
};
</script>
<style lang="scss" scoped>
.Verticalswiper {
  .swiper {
    height: 600px;
    width: 200px;
    margin-left: 0;
    margin-right: 0;
    // background: #6699ff;
    .mySwiper {
      width: 100%;
      height: 100%;
      .swiper-slide {
        // display: flex;
        // align-items: center;
        .haveicon {
          width: 100%;
          height: 290px;
          // background: #00a2eb;
          background: linear-gradient(
            to top,
            rgb(255, 228, 230),
            rgb(204, 251, 241)
          );

          text-align: center;
          padding-top: 50px;
          border-radius: 10px;
          .icon {
            text-align: center;
            span {
              display: inline-block;
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background: white;
            }
          }
          .userinfo {
            color: #ff0066;
          }
        }
      }
    }
  }
}
</style>
