<template>
  <div class="Hfadein">
    <div class="verswiper">
      <VerticalVue :vupdata="vupdatalist[0]" />
    </div>
    <div class="verswiper verspac1">
      <VerticalVue :vupdata="vupdatalist[1]" />
    </div>
    <div class="verswiper verspac2">
      <VerticalVue :vupdata="vupdatalist[2]" />
    </div>
    <div class="verswiper verspac3">
      <VerticalVue :vupdata="vupdatalist[3]" />
    </div>
    <div class="verswiper verspac2">
      <VerticalVue :vupdata="vupdatalist[4]" />
    </div>
    <div class="verswiper verspac1">
      <VerticalVue :vupdata="vupdatalist[5]" />
    </div>
    <div class="verswiper">
      <VerticalVue :vupdata="vupdatalist[6]" />
    </div>
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue";
import VerticalVue from "../../../components/swiper/vertical.vue";
import { gethomelist } from "../../../api/vup/vup";
export default {
  components: {
    VerticalVue,
  },
  setup() {
    onMounted(() => {
      gethomelists();
    });
    const formState = reactive({
      vupdatalist: [],
    });

    async function gethomelists() {
      let { code, data } = await gethomelist();
      if (code === 200) {
        formState.vupdatalist = group(data, 3);
      }
    }

    function group(data) {
      var result = [];
      for (var i = 0; i < data.length; i += 3) {
        result.push(data.slice(i, i + 3));
      }

      return result;
    }

    return {
      ...toRefs(formState),
    };
  },
};
</script>
<style lang="scss" scoped>
.Hfadein {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: absolute;
  padding-top: 20px;
  box-sizing: content-box;
  width: 100%;
  height: 70vh;
  bottom: 0;
  .verswiper {
    margin: 0 10px;
  }
  .verspac1 {
    transform: translate(0px, 50px);
  }
  .verspac2 {
    transform: translate(0px, 80px);
  }
  .verspac3 {
    transform: translate(0px, 110px);
  }
}
</style>
